@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}
::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}

.text-green {
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #058228;
}
.text-yellow {
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #cab008;
}

.text-red {
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #fb0606;
}

.modal-select{
  width: 100%
}